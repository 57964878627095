@font-face {
  font-family: "Gilroy-Medium";
  src: url(../../components/fonts/Gilroy-Bold.ttf);
}

.test-page {
  font-family: "Gilroy-Medium";
  width: 100%;

  .test-page-container {
    max-width: 1250px;
    padding: 7rem 40px;
    margin: 0 auto;

    .test-page-question {
      min-height: 90vh;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      p {
        padding: 0 10.5px;
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
      }

      @media (max-width: 893px) {
        padding-top: 100px;
        // border: 1px solid blue;

        .stc2 {
          .img-div-2 {
            .image-main-full {
              min-width: 10rem;
              width: 100%;
            }
          }
        }
        .test-page-choose {
          width: 100% !important;
          min-width: 300px;
        }
        p {
          font-size: 12px;
          padding: 0 10px;
        }

        .test-page-question__img {
          width: 50%;
        }
      }

      @media (max-width: 320px) {
        padding-top: 150px;
      }

      @media (min-width: 600px) and (max-width: 1400px) {
        border: 1px solid yellow;

        .test_1 {
          width: 80%;
          border: 1px solid red;
          display: flex;
          // flex-direction: column;
          // align-items: center;
          justify-content: center;
          .test-page-choose {
            width: 60%;
          }
        }
        .stc2 {
          .img-div-2 {
            .image-main-full {
              width: 100%;
            }
          }
        }
      }

      .test-page-question__img {
        margin: 20px 0;
      }

      .test-page-choose {
        min-width: 20rem;
        margin: 40px 0;
        align-self: flex-start;

        .test-page-choose__input {
          // min-width: 250px;
          // min-height: 100px;
          // padding: 20px;
          // display: flex;
          // flex-direction: column;
          // row-gap: 15px;
          // column-gap: 15px;
          // // border: 2px solid royalblue;
          // // border-bottom: 4px solid royalblue;
          // margin-bottom: 50px;

          min-width: "800px";
          width: "850px";
          max-width: "900px";
          // height: "100px",
          font-family: "Inter";
          font-weight: 500;

          display: "flex";
          justify-content: "space-evenly";
          // flexDirection: "column",
          flex-wrap: "wrap";

          label {
            display: flex;
            align-items: center;

            input {
              width: 20px;
              height: 20px;
              margin-right: 15px;
            }
          }
        }

        .submit-btn {
          padding: 10px 25px;
          background: royalblue;
          border: none;
          color: #fff;
          border-radius: 8px;
          font-size: 18px;
        }
      }

      .test-page-answers {
        min-height: 60vh;
        display: flex;
        align-items: center;
        flex-direction: column;
        column-gap: 20px;
        row-gap: 15px;
        margin-bottom: 80px;

        p {
          padding: 0 25px;
        }

        @media (max-width: 893px) {
          padding: 20px;
          margin-bottom: 80px;
          border: 1px solid yellow;

          p {
            font-size: 12px;
          }

          label {
            border: "1px solid rgb(183, 188, 193)";
            padding: "8px";
          }

          .test-page-answers-img {
            width: 50%;
          }
        }
      }
    }
  }

  .stc1 {
    display: flex;
    justify-content: center !important;
    align-items: start !important;
    // flex-direction: column;
    width: 100%;
    img {
      width: 800px;
      height: 200px;
      object-fit: contain;
    }
    span {
      word-break: break-word;
      font-size: 20px;
      text-align: start;
      align-items: start;
    }

    @media (min-width: 300px) and (max-width: 400px) {
      img {
        width: 300px;
      }
    }
    @media (min-width: 401px) and (max-width: 500px) {
      img {
        width: 100%;
      }
    }
    @media (min-width: 501px) and (max-width: 600px) {
      img {
        width: 500px;
      }
    }
    @media (min-width: 601px) and (max-width: 700px) {
      img {
        width: 600px;
      }
    }
    @media (min-width: 701px) and (max-width: 800px) {
      img {
        width: 700px;
      }
    }
  }

  .stc2 {
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .img-div-2 {
      width: 100%;
      height: 50%;
      img {
        height: 400px;
        object-fit: contain;
      }
    }
    span {
      font-size: 20px;
      text-align: start;
      align-items: start;
      word-break: break-word;
    }
  }
  .stc3 {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    .flex-img {
      display: flex;
      width: 100%;
      justify-content: space-between;
      img {
        width: 200px;
        height: 200px;
        object-fit: contain;
      }
    }
    span {
      font-size: 20px;
      text-align: start;
      align-items: start;
      word-break: break-word;
      margin-left: 20px;
    }
  }
  .text-o {
    font-size: 15px;
  }
  .text-m {
    font-size: 14px !important;
    width: 4rem;
  }
  .desc {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    line-height: 30px;
    .desc-body {
      box-shadow: -22px 0px 20px 0px rgba(0, 0, 0, 0.1);
      margin-bottom: 15px;
      .ques {
        width: 100%;
        padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        display: flex;
        justify-content: center;
        flex-direction: column;

        .stc1,
        .stc2,
        .stc3,
        .stc4 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 100%;
        }
      }
      .ans {
        width: 100%;
        margin-top: 10px;
        // padding: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        span {
          word-break: break-word;
          line-height: 20px;
        }
      }
      .desc-desc {
        width: 100%;
        margin-top: 10px;
        padding: 10px;
        display: flex;
        justify-content: flex-start !important;
        align-items: center;
        flex-direction: column;
        font-size: 11px !important;

        .stc1,
        .stc2,
        .stc3,
        .stc4 {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          align-items: center;
          width: 100%;
          span {
            font-size: 13px;
            word-break: break-word;
          }
        }
        span {
          word-break: break-word;
          line-height: 20px;
        }
        .desc-desc-body {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          img {
            width: 200px;
            height: 200px;
            object-fit: contain;
          }
        }
      }
    }
  }
  .main-results {
    margin-top: 40px;
    margin-bottom: 50px;
    span {
      font-size: 17px;
    }
    .results {
      margin-top: 10px;
      box-shadow: -22px 0px 20px 0px rgba(0, 0, 0, 0.1);
      padding: 10px;
      display: flex;
      flex-wrap: wrap;
      text-align: center;
      align-items: center;
      .result-con {
        display: flex;
        margin: 5px;
        flex-direction: column;
        h3 {
          font-size: 15px;
          font-weight: bold;
        }
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  .btn-final {
    padding: 10px;
    background: #00b3ff;
    color: white;
    border: none;
    justify-content: center;
    display: flex;
    margin-top: 10px;
  }
  .modal-alert-test-1 {
    width: 100%;
    height: 100%;

    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.862) !important;
    /* filter: blur(14px); */

    display: flex !important;
    justify-content: center !important;
    z-index: 1000;
    box-shadow: 0 10px 60px -3px rgba(0, 0, 0, 0.1);
    text-align: center;
    align-items: center;
    /* border: 1px solid red; */
    // position: fixed;
    // top: 30%;
    // left: 40%;
    // display: flex !important;
    // justify-content: center !important;
    // z-index: 1000;
    // width: 350px;
    // background: #ffffff !important;
    // box-shadow: 0 10px 60px -3px rgba(0, 0, 0, 0.1);
    // text-align: center;
    // align-items: center;
  }

  .modal-alert-test-1 .modal-alert-test-body {
    background: white;
    filter: blur(0);

    /* border: 1px solid red; */
    color: black;
    display: flex !important;
    box-shadow: 0 10px 60px -3px rgba(0, 0, 0, 0.1);
    padding: 50px;
    text-align: center;
    align-items: center;
    /* min-height: 400px; */
    justify-content: center !important;
    /* z-index: 1001; */
    // color: black;
    // display: flex !important;
    // box-shadow: 0 10px 60px -3px rgba(0, 0, 0, 0.1);
    // //margin: 50px;
    // padding: 50px;
    // width: 100%;
    // height: 100%;
    // text-align: center;
    // align-items: center;
    // justify-content: center !important;
  }

  .modal-alert-test-1 .modal-alert-test-body .bod {
    height: 100%;
    text-align: center;
    align-items: center;
    box-shadow: 0px 10px 60px -3px rgba(0, 0, 0, 0.1);
  }
  .modal-alert-test .modal-alert-test-body .bod span {
    font-size: 20px;
    font-weight: bolder;
  }
  .modal-alert-test-1 .modal-alert-test-body .bod .btns-test {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .modal-alert-test-1 .modal-alert-test-body .bod .btns-test button {
    padding: 10px !important;
    width: 40%;
    margin-top: 20px;
    background: #006aff;
    border: none;
    outline: none;
    color: white;
    border-radius: 10px;
  }
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url(../fonts/Gilroy-Medium.ttf);
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url(../fonts/Gilroy-Bold.ttf);
}

@font-face {
  font-family: "Samsung Sharp Sans";
  src: url(../fonts/samsungsharpsans.otf);
}
.MuiAutocomplete-root {
  width: 100%;
}
.MuiAutocomplete-popper {
  width: 300px !important;
}
.MuiAutocomplete-popupIndicator {
  display: none !important;
}
.MuiOutlinedInput-notchedOutline {
  border-width: 0 !important;
  height: max-content;
}
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]
  .MuiAutocomplete-input,
.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding: 0 !important;
}
.search_option {
  display: flex;
}
.search_option h4 {
  color: #006aff;
  font-size: 15px;
}
.search_option p {
  color: #c1c1c1;
  font-size: 14px;
}
.search_option .search_option_img img {
  width: 55px;
  margin-right: 5px;
}
.ytp-contextmenu {
  display: none !important;
}
a {
  text-decoration: none;
  color: black;
}

button {
  cursor: pointer;
}

body {
  background: #ffffff;
}

a p h1 h2 h3 h4 h5 h6 {
  font-family: "Gilroy-Medium";
}

.container {
  max-width: 1250px;
  margin: 0 auto;
}
#search-btn {
  display: none;
}
.fixed {
  height: 100px;
}

.menu__login {
  display: none;
}

.navbar__asos {
  height: 100px;
  position: fixed;
  width: 100%;
  background: #ffffff;
  top: 0;
  z-index: 10;
}
.file-reader {
  z-index: 11;
}
.pdf-reader {
  z-index: 11;
}
nav {
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: "Gilroy-Medium";
  background: #ffffff;
  margin: 0 auto;
}
.courses-button {
  background: #006aff;
  border: none;
  border-radius: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: #fff;
  padding: 10px 15px;
}
.courses-button p {
  margin-right: 10px;
}
.gamburger {
  display: none;
}
.result-search {
  width: 100%;
  background: red;
  height: 200px;
}
.navbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-end;
  width: 100%;
  margin-left: 50px;
}

.nav__bar {
  width: 40%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 auto;
}

.nav__bar a {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(17, 17, 17, 0.54);
}

.nav__bar .nav_btn {
  background: none;
  outline: none;
  border: none;
  width: 0;
  height: 0;
}

.nav__bar .activ__nav {
  color: #111111;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.nav__bar .activ__nav::after {
  content: "";
  width: 36px;
  height: 2px;
  background: #006aff;
  border-radius: 6px;
  position: absolute;
  margin-top: 20px;
}

.nav__bar ul {
  display: -webkit-box;
  justify-content: space-between;
  width: 100%;
  display: -ms-flexbox;
  display: flex;
}

.nav__bar li {
  list-style-type: none;
  margin: 0 auto;
}

.logo a {
  width: 120px;
}

.logo a img {
  width: 120px;
  height: 100%;
  aspect-ratio: 3/2;
  /*object-fit: contain;*/
}

.menu425 {
  display: none;
}

.login__bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.login__bar .close__btn {
  background: none;
  outline: none;
  border: none;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 0;
  height: 0;
  margin: 0;
  padding: 0;
}

.login__bar button {
  outline: none;
  border: none;
  background: none;
  margin-left: auto;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

#search-btn {
  margin-left: 30px;
}

.login__bar button img {
  width: 20px;
}

.login__bar .kirish {
  padding: 15px 40px;
  background: #e7f0ff;
  border-radius: 14px;
  text-align: center;
  margin: 0 auto;
  font-family: "Gilroy-Bold";
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  letter-spacing: 0.015em;
  color: #006aff;
  text-decoration: none;
}

.language {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin: 0 10px;
  cursor: pointer;
  padding: 8.9px;
  border: 1px solid #6796e9;
  position: relative;
  border-radius: 10px;
}
.language .MuiSvgIcon-colorPrimary {
  color: #6796e9;
  margin-right: 5px;
}
.language p {
  color: #6796e9;
}

.language img {
  margin-left: 5px;
}

.language .languages {
  width: 100%;
  display: none;
  cursor: pointer;
}

.language ul {
  position: absolute;
  margin: 125px 0px 0px -10px;
  padding: 0 20px 20px 20px;
  background: #c3d4f1;
  border-radius: 18px;
}

.language li {
  list-style-type: none;
  margin: 5px 0;
  width: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
}

.language li a {
  margin: 0 auto;
}

.language li:hover {
  background: #e7f0ff;
  border-radius: 18px;
}

.language:hover .languages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.search {
  width: 100%;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  background: #fcfcfc;
  -webkit-transition: 0.5s;
  margin: 0 10px;
  transition: 0.5s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.search button {
  background: none;
  outline: none;
  border: none;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.balance-nav {
  color: #111111;
}
.search input {
  height: 30px;
  background: #fcfcfc;
  width: 100%;
  border: none;
  outline: none;
}

.search img {
  width: 20px;
  margin-right: 10px;
}

.search__input {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-radius: 20px;
  padding: 7px;
}

.search__input button {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
}

@media (max-width: 1250px) {
  .nav__bar li {
    margin: 0 25px;
  }
  .nav-container {
    padding: 0 28px;
  }
}
@media (max-width: 1080px) {
  .navbar {
    margin-left: 10px;
  }
  .nav__bar li {
    margin: 0 18px;
  }
  .nav__bar {
    margin-right: 0;
  }

  .logo a img {
    width: 100px;
    height: auto;
  }
}
@media (max-width: 1024px) {
  .for_desktop {
    display: none;
  }
  #search-btn {
    display: flex;
  }
  .search {
    width: 100%;
  }
  .menu425 {
    display: flex;
  }
  .container {
    max-width: 1024px !important;
  }
  .nav__bar li {
    margin: 0 14px;
  }
  .bilimolish__3 {
    max-width: 900px;
  }
  nav {
    margin: 0px !important;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: space-between;
    padding: 0 20px;
  }
  .search input {
    width: 400px;
    margin-right: 0px;
  }
}
@media (max-width: 955px) {
  .nav__bar li {
    margin: 0 10px;
  }
}
@media (max-width: 920px) {
  .nav__bar {
    width: 100%;
    padding: 0 5px;
  }
  .nav__bar ul {
    justify-content: space-between;
    width: 100%;
  }
  .nav-container {
    padding: 0 15px;
  }
  .nav__bar li {
    margin: 0 3px;
  }
  .language {
    margin: 0 10px;
  }
  #search-btn {
    margin-left: 15px;
  }
  .login__bar .kirish {
    padding: 10px 20px;
    border-radius: 10px;
  }
  .logo a img {
    width: 90px;
  }
}
@media (max-width: 1024px) {
  .bilimolish__3 {
    padding: 0 30px;
  }
  .bilimolish__4__slayd {
    padding: 0 30px;
  }
  .nav__bar {
    margin-right: 0;
  }
  .gamburger {
    display: none;
  }
  .gamburger img {
    width: 20px;
  }
  .gamburger button {
    background: none;
    border: none;
    outline: none;
  }
  .search input {
    width: 80% !important;
    margin-right: 0px;
  }
  nav {
    -ms-flex-pack: distribute;
    justify-content: space-between;
    padding: 0 20px;
  }

  .gamburger ul {
    display: none;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    background: white;
    z-index: 99;
    opacity: 0.97;
    -webkit-transition: 0.5s linear;
    transition: 0.5s linear;
    list-style: none;
  }
  .gamburger a {
    font-family: Gilroy-Medium;
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: rgba(17, 17, 17, 0.54);
  }

  .gamburger {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 0;
  }
  /* .gamburger .menu__login {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 10px;
    right: 60px;
  } */
  /* .gamburger .menu__close {
    display: block;
    position: absolute;
    top: 10px;
    right: 60px;
  } */

  .search__input form {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .nav__bar ul {
    margin-right: 0;
  }
  .videoPromo video {
    width: 100%;
    height: auto;
  }
  .closeVideo {
    top: 40px;
    right: 10px;
  }
}

@media (max-width: 1024px) {
  .logo a img {
    width: 100px;
  }
  .cancelPopup {
    padding: 1px 5px 6px 5px;
  }
  .login__bar {
    width: 100%;
  }
  .nav__bar {
    display: none;
  }
  .courses-button {
    display: none;
  }
  .navbar {
    display: unset;
  }
  .gamburger {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gamburger ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0;
    overflow: hidden;
    transition: none;
  }

  .gamburger ul li {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .gamburger .header-link a {
    padding: 15px 25px;
    box-sizing: border-box;
  }

  .gamburger ul.toggle {
    width: 100%;
  }
  .logo a img {
    width: 85px;
    margin-left: 0;
  }
  .section-spiker {
    padding-left: 0 !important;
  }
  .navbar__asos nav {
    padding: 0 5% !important;
  }
  .nav__bar ul {
    margin-right: 0;
  }
  .menu__login {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 90px;
  }
  .menu__login__tillar {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }
  .menu__login__tillar a {
    margin-left: 10px;
    margin-right: 10px;
  }

  .menu__login__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  .menu__login__btn a {
    padding: 17px 40px;
    background: #e7f0ff;
    border-radius: 14px;
    text-align: center;
    margin: 0 auto;
    font-family: "Gilroy-Bold";
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    letter-spacing: 0.015em;
    color: #006aff;
    text-decoration: none;
  }
  nav {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px !important;
  }
  .language {
    display: none;
  }
  .kirish {
    display: none !important;
  }

  /* .menu425 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
  } */

  .menu425.active span {
    height: 0;
  }
  .menu425.active span::before {
    transform: translateY(0) rotate(45deg);
    height: 2px;
    border-radius: 30px;
    background: #fff;
  }
  .menu425.active span::after {
    border-radius: 30px;
    transform: translateY(0) rotate(-45deg);
    height: 2px;
    background: #fff;
  }

  .menu425 span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 24px;
    height: 3px;
    background: rgb(78, 78, 78);
  }
  .menu425 span::before {
    content: "";
    position: absolute;
    width: 24px;
    height: 3px;
    background: rgb(78, 78, 78);
    transform: translateY(-7px);
  }
  .menu425 span::after {
    content: "";
    position: absolute;
    width: 24px;
    height: 3px;
    background: rgb(78, 78, 78);
    transform: translateY(7px);
  }
  .gamburger button {
    display: none;
  }
  /* .gamburger .menu__close {
    top: 35px;
    right: 35px;
  } */

  .search button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .search button span {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 0;
  }

  .search button span::before {
    content: "";
    position: absolute;
    width: 24px;
    background: rgb(78, 78, 78);
    transform: translateY(0) rotate(45deg);
    height: 2px;
    border-radius: 30px;
  }

  .search button span::after {
    content: "";
    position: absolute;
    width: 24px;
    background: rgb(78, 78, 78);
    border-radius: 30px;
    transform: translateY(0) rotate(-45deg);
    height: 2px;
  }
  .search__input {
    margin-left: 0;
    background: #fcfcfc;
  }
}

/* 
@media (max-width: 375px) {
  .login__bar {
    margin-left: 80px;
  }
  .search__input {
    margin-left: 0;
    z-index: 1;
    background: white;
  }
  .search input {
    width: 240px;
    margin-left: 20px;
  }
} */

/* @media (max-width: 320px) {
  .login__bar {
    margin-left: 30px;
  }
} */

@media only screen and (max-width: 1024px) {
  .menu425 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 30px;
    height: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .closeVideo {
    top: 120px;
    right: 10px;
  }
  .menu425 span::after {
    width: 20px;
    height: 3px;
  }
  .menu425 span::before {
    width: 20px;
    height: 3px;
  }
  .menu425 span {
    width: 20px;
    height: 3px;
  }
}

@media (max-width: 330px) {
  .search input {
    width: 80% !important;
  }
}

.search button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 30px;
  height: 30px;
}

.search button span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0;
}

.search button span::before {
  content: "";
  position: absolute;
  width: 20px;
  background: rgb(78, 78, 78);
  transform: translateY(0) rotate(45deg);
  height: 2px;
  border-radius: 30px;
}
.search__input form {
  display: flex;
  width: 100%;
}

.ic {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.search img {
  margin-right: 0;
}

.search button span::after {
  content: "";
  position: absolute;
  width: 20px;
  background: rgb(78, 78, 78);
  border-radius: 30px;
  transform: translateY(0) rotate(-45deg);
  height: 2px;
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url(../fonts/Gilroy-Medium.ttf);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  max-width: 1250px;
  margin: 0 auto;
}

.mainSignUpPage2 {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 150px;
}
.signUpPage-input-item2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.resend-report {
  width: 100%;
  margin-top: 160px;
  padding-left: 20px;
  font-size: 14px;
  color: rgba(34, 34, 34, 0.801);
  font-family: Arial, Helvetica, sans-serif;
}

.signUpPage-inner-item2 {
  width: 398px;
  height: 80px;
  padding-left: 35px;
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  color: rgba(17, 17, 17, 0.36);
  background: rgba(17, 17, 17, 0.02);
  border-radius: 24px;
  border: none;
  outline: none;
  margin-top: 5px;
}

.sectionLoginPage + .footer {
  display: none !important;
}
.sectionLoginPage ~ .footer_vector {
  display: none !important;
}
.sectionSignUpPage + .footer {
  display: none !important;
}
.sectionSignUpPage ~ .footer_vector {
  display: none !important;
}
.sectionSignUpPage2 + .footer {
  display: none !important;
}
.sectionSignUpPage2 ~ .footer_vector {
  display: none !important;
}
.sectionSignUpPage3 + .footer {
  display: none !important;
}
.sectionSignUpPage3 ~ .footer_vector {
  display: none !important;
}
.ParolUnut_1_block + .footer {
  display: none !important;
}
.ParolUnut_1_block ~ .footer_vector {
  display: none !important;
}
.ParolUnut_2_block + .footer {
  display: none !important;
}
.ParolUnut_2_block ~ .footer_vector {
  display: none !important;
}
.ParolUnut_3_block + .footer {
  display: none !important;
}
.ParolUnut_3_block ~ .footer_vector {
  display: none !important;
}
.signUpPage-btn-inner2 {
  width: 398px;
  height: 80px;
  padding: 27px 125px 27px 125px;
  background: #e7f0ff;
  border-radius: 24px;
  border: none;
  margin-top: 24px;
}
.signUpPage-btn-inner2 a {
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  color: #006aff;
  text-decoration: none;
}
.bottom-space {
  margin-bottom: 0;
  margin-top: 10px;
}
.signUpPage-text-item2 {
  margin-top: 34px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(17, 17, 17, 0.36);
  margin-left: -20px;
}
.signUpPage-text-item2 a {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: #006aff;
  text-decoration: none;
}

.timer {
  width: 100%;
  max-width: 340px;
  padding: 20px 20px 0 0;
  margin-right: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.timer button {
  margin-right: 10px;
  background-color: transparent;
  color: #a9a9a9;
  /* opacity: 1; */
  font-size: 18px;
  border: none;
  outline: none;
}

.timer span {
  font-size: 18px;
  font-family: sans-serif;
  color: rgb(32, 32, 32);
}

.message {
  position: fixed;
  top: 100px;
  right: 100px;
  background-color: rgb(129, 162, 250);
}

@media (max-width: 768px) {
  .mainSignUpPage2 {
    padding-bottom: 100px;
  }
  .signUpPage-inner-item2 {
    margin-top: 100px;
  }
  .footer {
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 515px) {
  .footer {
    width: 100%;
    margin-top: 0;
    padding-top: 30px;
  }
  .footer.container {
    margin-top: 0 !important;
    padding-top: 15px;
  }
}
@media (max-width: 375px) {
  .signUpPage-inner-item2 {
    width: 350px;
    font-size: 20px;
  }
  .signUpPage-btn-inner2 {
    width: 350px;
    padding: 25px 110px 25px 110px;
  }
  .signUpPage-btn-inner2 a {
    font-size: 21px;
  }
  .signUpPage-text-item2 {
    font-size: 16px;
  }
  .signUpPage-text-item2 a {
    font-size: 16px;
  }
}
@media (max-width: 320px) {
  .signUpPage-btn-inner2 {
    width: 300px;
    padding: 25px 80px 25px 80px;
  }
  .signUpPage-inner-item2 {
    width: 300px;
    font-size: 18px;
  }
  .signUpPage-btn-inner2 a {
    font-size: 20px;
  }
  .signUpPage-text-item2 {
    font-size: 14px;
  }
  .signUpPage-text-item2 a {
    font-size: 14px;
  }
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url(../../components/fonts/Gilroy-Bold.ttf);
}

.header-test {
  width: 100%;
  background: blue;
  color: aliceblue;
  padding: 10px 8rem;
  font-family: "Gilroy-Medium";
  position: fixed;
  z-index: 9;

  .nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //  column-gap: 20px;
    //  row-gap: 20px;
    flex-wrap: wrap;

    .user-id {
      display: flex;
      flex-direction: column;
      font-size: 14px;
    }

    .nav-mark-btn {
      display: flex;
      gap: 5px;

      input {
        cursor: pointer;
      }
    }

    .transfer-btns {
      display: flex;
      gap: 20px;

      span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: 12px;
        cursor: pointer;
      }
    }

    .full-screen {
      display: none;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .question-btn {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .chemistry-btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .notes-btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .calculator-btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .revers-color-btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .text-zoom {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }

    .setting-btn {
      display: flex;
      flex-direction: column;
      align-items: center;

      span {
        margin-top: 5px;
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1000px) {
  .header-test {
    width: 100%;
    background: blue;
    color: aliceblue;
    padding: 10px 10px;
    font-family: "Gilroy-Medium";
    position: fixed;
    z-index: 9;

    // color: rgb(183, 188, 193);
    .nav {
      //  overflow-y: scroll;
      min-width: 300px;
      // width: 400px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      //  column-gap: 20px;
      //  row-gap: 20px;
      //  flex-wrap: wrap;
      padding: 0 !important;
    }
  }
}

ul,
li,
ol {
  list-style: none;
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url("../fonts/Gilroy-Medium.ttf");
}

@font-face {
  font-family: "Gilroy-Bold";
  src: url("../fonts/Gilroy-Bold.ttf");
}

/* @font-face {
  font-family: 'Samsung Sharp Sans';
  src: url(../fonts/samsungsharpsans-bold.otf);
} */
@font-face {
  font-family: "Samsung-Sharp-Sans-Bold";
  src: url("../fonts/samsungsharpsans-bold.otf");
}

* {
  margin: 0;
  padding: 0;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  font-family: sans-serif;
}

.wrapper {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.wrapper ._main {
  flex: 1 1 !important;
}

.error-bottom .buttons .btn {
  color: #000;
  border-color: transparent;
}

.home-error {
  color: black;
  border-radius: 4px;
  padding: 7px 15px;
  margin-top: 20px;
  border-color: transparent;
}

.home-error:hover {
  background: #006aff;
  color: #ffffff;
  transition: 0.5s;
}
.main_page-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-course-description {
  display: flex;
  margin-top: 10px;
  margin-bottom: 39px;
}
.header-course-description p {
  font-size: 24px;
  font-weight: 400;
  color: #877676 !important;
  /* margin-left: 7px; */
}
.section__1 {
  width: 100%;
}
.section__1 .slider1-container > div {
  margin: 36px auto;
  margin-left: auto !important;
}
.section__1 .slider1-container {
  background: #e7f0ff;
  width: 100%;
}
.section__1 .swiper-container .swiper-wrapper {
  align-items: center;
}
.section__1 .swiper-container .swiper-wrapper .swiper-slide {
  padding: 0 5%;
}
.section__1 .swiper-container .swiper-button-next,
.section__1 .swiper-container .swiper-button-prev {
  padding: 5px;
  height: 35px;
  border-radius: 25px;
}
.main_page-img_box {
  margin-left: 10px;
  padding: 20px;
}
.main_page-img_box img {
  max-width: 412px;
  border-radius: 25px;
  box-shadow: 0px 0px 20px 0px #006aff54;
}
.main_page-wrapper h1 {
  font-size: 55px;
  font-weight: 600;
  color: #006aff;
}
.main_page-wrapper h4 {
  font-size: 25px;
  font-weight: 500;
  margin: 24px 0;
}
.main_page-btn_box {
  display: flex;
}
.main_page-btn_box button {
  margin: 0 5px;
}
.main_page-btn_primary {
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fafafa;
  width: 154px;
  border: none;
  height: 51px;
  background: #006aff;
  border-radius: 14px;
}
.main_page-btn_clear {
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-weight: 500;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #006aff;
  width: 154px;
  border: none;
  height: 51px;
  background: #fff;
  border-radius: 14px;
}
#notfound {
  position: relative;
  height: 50vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 520px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
}
.send-code {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.452);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000001;
  transform: scale(0);
}
.send-code h1 {
  font-family: Gilroy-Medium;
  font-size: 25px;
  line-height: 23px;
  color: rgba(17, 17, 17, 0.72);
  width: 100%;
  margin-top: 10px;
}
.send-code .send-code-inner input {
  background: rgba(17, 17, 17, 0.03);
  border-radius: 18px;
  width: 85%;
  height: 20%;
  margin-top: 20px;
  text-align: center;
  letter-spacing: 20px;
  border: 3px solid #cccccc;
  outline: none;
  font-size: 20px;
  color: rgba(17, 17, 17, 0.9);
  position: relative;
}
.file-reader .cui-toolbar-button-right {
  display: none !important;
}
.file-reader .cui-statusbar span:last-child {
  display: none !important;
}
.file-reader .cui-toolbar-buttondock .alignright {
  display: none !important;
}
.send-code-inner {
  border: 2px solid #006aff;
  background: white;
  border-radius: 10px;
  text-align: center;
  z-index: 1000000002;
  padding: 20px;
}
.send-code-inner .btn-sms {
  display: flex;
  justify-content: space-evenly;
}
.send-code-inner .btn-sms button {
  width: 38%;
  box-sizing: border-box;
  border-radius: 18px;
  font-family: Gilroy-Medium;
  font-size: 15px;
  line-height: 28px;
  color: #006aff;
  background: #e7f0ff;
  outline: none;
  border: 3px solid #e7f0ff;
}
.send-code-inner .input-sms {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
}
.send-code-inner .input-sms input {
  padding: 10px;
}
.send-code-inner .input-sms a {
  font-size: 15px;
  color: #006aff;
  cursor: pointer;
}
/*.send-code-inner{*/
/*  position: absolute;*/
/*  padding: 10px 40px;*/
/*  border: 2px solid #006AFF;*/
/*  background: white;*/
/*  z-index: 1000000001;*/
/*  text-align: center;*/
/*  border-radius: 10px;*/
/*  transform:translateX(-10000px);*/
/*  transition: all cubic-bezier(0.16, 0.85, 0.22, 1.04) 0.5s;*/
/*}*/

/*.send-code.active1 .send-code-inner {*/
/*  transition: all cubic-bezier(0.16, 0.85, 0.22, 1.04) 0.5s;*/
/*  transform:translateX(0);*/
/*}*/

.send-code.active-code {
  transform: scale(1);
}
.sotib-olindi {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.452);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000000;
  transform: scale(0);
}

.sotib-olindi-inner {
  position: absolute;
  padding: 10px 40px;
  border: 2px solid #006aff;
  background: white;
  z-index: 1000000001;
  text-align: center;
  border-radius: 10px;
  transform: translateX(-10000px);
  transition: all cubic-bezier(0.16, 0.85, 0.22, 1.04) 0.5s;
}

.sotib-olindi.active1 {
  transform: scale(1);
}

.sotib-olindi.active1 .sotib-olindi-inner {
  transition: all cubic-bezier(0.16, 0.85, 0.22, 1.04) 0.5s;
  transform: translateX(0);
}

.sotib-olindi-inner h3 {
  font-family: sans-serif;
  font-size: 25px;
  color: #006aff;
}
.sotib-olindi-inner h4 {
  font-family: sans-serif;
  font-size: 25px;
  margin-top: 10px;
  color: black;
}

.sotib-olindi-inner button {
  background: #006aff;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-top: 10px;
  color: white;
  border: 2px solid transparent;
}
.sotib-olindi-inner button:hover {
  background: #fff;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  padding: 5px 10px;
  box-sizing: border-box;
  margin-top: 10px;
  color: #006aff;
  border: 2px solid #006aff;
}

.notfound .notfound-404 {
  position: relative;
  height: 240px;
}

.notfound h2 {
  font-family: "Cabin", sans-serif;
  font-size: 20px;
  font-weight: 400;
  text-transform: uppercase;
  color: #000;
  margin-top: 0;
  margin-bottom: 25px;
}

.notfound .notfound-404 h3 {
  font-family: "Cabin", sans-serif;
  position: relative;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  color: #262626;
  margin: 0;
  letter-spacing: 3px;
  padding-left: 6px;
}

.notfound .notfound-404 h1 {
  font-family: "Montserrat", sans-serif;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-size: 252px;
  font-weight: 900;
  margin: 0;
  color: #262626;
  text-transform: uppercase;
  letter-spacing: -40px;
  margin-left: -20px;
}

.notfound .notfound-404 h1 > span {
  text-shadow: -8px 0px 0px #fff;
}

.background__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  z-index: -1;
  top: 130px;
}

.background__img .ellipse {
  width: 236px;
  height: 328px;
  -webkit-filter: opacity(65%);
  filter: opacity(65%);
}

.background__img .cursor {
  width: 300.01px;
  height: 268px;
  -webkit-filter: opacity(15%);
  filter: opacity(15%);
}

.section__1 {
  /* margin-top: -280px !important; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  overflow: hidden;
}

.sec__1_video__2 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.sec__1_video__1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.sec__1_h1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  max-width: 656px;
  height: auto;
  margin: 0 auto;
  padding: 10px;
}

.sec__1_h1 img {
  width: 24px;
}

.sec__1_h1 button {
  background: none;
  outline: none;
  border: none;
}

.sec__1_h1 h1 {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: bold !important;
  font-size: 70px;
  line-height: 54px;
  text-align: center;
  color: #006aff;
}

.sec__1_h1 h1 span {
  font-family: Gilroy-Bold;
  font-size: 35px;
  color: #545454;
  padding-top: 20px;
}

.sec__1_p p {
  font-family: Gilroy-Bold;
  font-size: 18px;
  line-height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #111111;
  opacity: 0.72;
}

.sec__1_video {
  margin-top: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sec__1_video img {
  width: 24px;
  margin-left: 18px;
  margin-right: 8px;
}

.sec__1_video p {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #006aff;
}

.sec__1_video a {
  padding: 16px 30px;
  background: #006aff;
  border-radius: 12px;
  outline: none;
  border: none;
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #ffffff;
  margin-right: 18px;
}

.sec__1_btn {
  margin-top: 34px;
  margin-left: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-bottom: 38px;
}

.sec__1_btn button {
  width: 54px;
  height: 24px;
  background: #006aff;
  border-radius: 24px;
  outline: none;
  border: none;
  margin: 0 36px;
}

.sec__1_btn button p {
  width: 18px;
  height: 18px;
  border-radius: 24px;
  background: white;
  -webkit-margin-before: 0em;
  margin-block-start: 0em;
  -webkit-margin-after: 0em;
  margin-block-end: 0em;
  color: black;
}

.sec__1_btn h2 {
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #006aff;
}

.sec__1_btn h4 {
  display: none !important;
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #006aff;
}

.sec__1_btn h3 {
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: rgba(17, 17, 17, 0.54);
}

.sec__1_btn h5 {
  display: none !important;
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: rgba(17, 17, 17, 0.54);
}

.bilimolish .sec__1_btn button p {
  margin-left: 3px;
}

.bilimberish .sec__1_btn button p {
  margin-left: 31px;
}

.sec__1_group {
  margin-bottom: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.sec__1_group a {
  margin: 0 auto;
}
/* .bilimolish {
  margin-top: 220px;
} */
@media (max-width: 1024px) {
  .main_page-wrapper h1 {
    font-size: 3.25rem;
    margin-top: 15px;
  }
  .main_page-wrapper {
    flex-direction: column-reverse;
    justify-content: center;
  }
  .main_page-wrapper_in {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .header-course-description p {
    text-align: center;
  }
  .main_page-img_box img {
    max-width: 500px;
  }
  .main_page-wrapper h1 {
    text-align: center;
    font-size: 35px !important;
  }
}
@media only screen and (max-width: 690px) {
  /* .main_page-wrapper h1 {
    font-size: 25px !important;
  } */
  .sec__1_group a {
    margin: 10px auto;
  }
}
.sec__1_group img {
  width: 62px;
  height: 54px;
  margin-top: 15px;
}

.sec__1_group .group {
  width: 190px;
  height: 131px;
  margin: 0 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background: #ffffff;
  border: 2px solid rgba(17, 17, 17, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 24px 30px -2px rgba(17, 17, 17, 0.04);
  box-shadow: 0px 24px 30px -2px rgba(17, 17, 17, 0.04);
  border-radius: 24px;
}

.sec__1_group .group p {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 19px;
  align-items: center;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #111111;
}

.bilimolish__2 {
  margin-bottom: 100px !important;
}

.bilimolish__2__h1 {
  margin-bottom: 36px;
}

.bilimolish__2__h1 h1 {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 58px;
  text-align: center;
  color: #006aff;
}

.bilimolish__2__h1 h1 span {
  color: #111111;
  margin-left: 10px;
}

.bilimolish__2__a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 36px;
}

.bilimolish__2__a a {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fafafa;
  width: 154px;
  height: 51px;
  background: #006aff;
  border-radius: 14px;
}

.bilimolish__2__slayd {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
}
.bilimolish__2__slayd a:nth-child(1) {
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px,
    rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  border-radius: 18px;
  background: transparent;
}

.bilimolish__2__slayd .swiper-container {
  padding: 10px 35px;
}

.bilimolish__2__slayd .swiper-btn-next img {
  width: 30px;
  height: 30px;
}

.bilimolish__2__slayd .swiper-btn-prev img {
  width: 30px;
  height: 30px;
}

.bilimolish__2__slayd .bilimolish__2__slayd__img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  width: 210px;
  height: 158px;
  background: transparent;
}

.bilimolish__2__slayd .swipper__1 {
  width: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
}

.bilimolish__2__slayd .imgbig {
  width: 252px;
  height: 155px;
  border-radius: 18px;
  -o-object-fit: cover;
  object-fit: revert;
}
/* 
.bilimolish__2__slayd .imgbig__div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-mask-image: url(../icons/Asset.png);
  mask-image: url(../icons/Asset.png);
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-size: 100%;
  mask-size: 100%;
} */

.bilimolish__2__slayd .imgsmall {
  width: 54px;
  height: 54px;
  border-radius: 17px;
  position: absolute;
  background: #ffffff;
  margin: 10px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.bilimolish__2__slayd .title {
  width: 250px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.72);
  margin-top: 15px;
  margin-bottom: 10px;
  min-height: 15px;
}

.bilimolish__2__slayd .title img {
  width: 20px;
  height: 20px;
}

.bilimolish__2__slayd .feedback {
  /* margin-top: 15px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  width: 250px;
  border-bottom: 2px solid rgba(17, 17, 17, 0.06);
  padding-bottom: 18px;
}

.bilimolish__2__slayd .feedback .stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.bilimolish__2__slayd .feedback .stars p {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #006aff;
  margin-left: 8px;
  margin-right: 20px;
}

.bilimolish__2__slayd .feedback .stars p span {
  color: #006aff50;
}

.bilimolish__2__slayd .feedback .views {
  width: max-content !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.bilimolish__2__slayd .feedback .views p {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #006aff;
  margin-left: 5px;
}
.views svg {
  width: 15px;
  height: 15px;
}
.cart-img {
  margin-left: 10px;
}
.bilimolish__2__slayd .price {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 250px;
}

.bilimolish__2__slayd .price h5 {
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #111111;
}

.bilimolish__2__slayd .price h5 span {
  font-size: 14px;
  font-weight: 200;
  color: #575757;
  margin-left: 3px;
}
.bilimolish__2__slayd .price h6 span {
  font-weight: 200;
  font-size: 12px;
  color: #575757;
}

.bilimolish__2__slayd .price h6 {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  color: #006aff;
  text-decoration: line-through;
}

.bilimolish__2__slayd .price a {
  min-width: 120px;
  min-height: 40px;
  background: rgba(17, 17, 17, 0.06);
  border-radius: 12px;
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: rgba(17, 17, 17, 0.54);
  box-shadow: none;
}
.bilimolish__2__slayd .price a:hover {
  background: #006aff;
  color: white;
}

.bilimolish__2__slayd .price p {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 19px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #ffffff;
  width: 58px;
  height: 31px;
  background: #006aff;
  border-radius: 8px;
}
.bilimolish__2__slayd .price.priceRus p {
  width: 75px;
  font-size: 13px;
}

.bilimolish__2__slayd .swiper-button-prev:after,
.bilimolish__2__slayd .swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
  background: url(../icons/Left.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  left: 0;
  z-index: 200;
}

.bilimolish__2__slayd .swiper-button-next:after,
.bilimolish__2__slayd .swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
  background: url(../icons/Right.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  right: 0px;
  z-index: 200;
}

.bilimolish__2__slayd .swiper-button-prev {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.bilimolish__2__slayd .swiper-button-next {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.modal-alert {
  /* width: 100%; */
  height: max-content;
  position: fixed;
  top: 120px;
  /* left: 0; */
  right: 120px;
  /* bottom: 0; */
  /* background: rgba(0, 0, 0, 0.466); */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 90;
  transition: 0.3s;
  animation: modalAlert 0.6s linear forwards 1s;
  transform: translateX(160%);
}

.modal-alert-title {
  position: relative;
  padding: 10px 0 10px 0;
  width: 260px;
}

.modal-alert-title .button-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.modal-alert-title .button-wrapper a {
  border-radius: 8px;
  padding: 8px 15px;
  box-sizing: border-box;
  color: white;
  border: 2px solid transparent;
  outline: none;
  background: #006aff;
}

.modal-alert-title .button-wrapper a {
  color: white;
  font-size: 14px;
}
.modal-alert-title .button-wrapper .cancelPopup {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-130%, 0%);
  background-color: #e7f0ff;
  margin-bottom: 10px;
  padding: 1px 5px 5px 5px;
  border-radius: 50%;
  box-sizing: border-box;
  color: #006aff;
  outline: none;
  cursor: pointer;
}

@media (max-width: 576px) {
  .main_page-img_box img {
    max-width: 310px;
  }
  .main_page-wrapper h1 {
    font-size: 20px !important;
  }
  .header-course-description p {
    font-size: 16px !important;
  }
  /* .modal-alert-title {
    padding: 30px 20px 10px 20px;
    width: 300px;
  } */

  .modal-alert-title .button-wrapper a {
    font-size: 13px;
    padding: 5px 10px;
  }

  .modal-alert-title .button-wrapper .cancelPopup {
    font-size: 13px;
  }
}

@keyframes modalAlert {
  100% {
    transform: translate(39%, -28%);
    opacity: 1;
  }
}

.modal-alert .modal-alert-title {
  border: 1px solid transparent;
  background-color: #e7f0ff;
  border-radius: 10px;
}

.modal-alert .modal-alert-title h1 {
  font-size: 14px;
  font-family: Gilroy-Bold;
  color: #006aff;
  padding-bottom: 5px;
  text-align: center;
}

.modal-alert .modal-alert-title img {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 210px;
  top: 2px;
  border-radius: 10px;
  background: #e7f0ff;
  padding: 7px;
}

.bilimolish__2__slayd .swiper-button-prev,
.bilimolish__2__slayd .swiper-container-rtl .swiper-button-next {
  left: -2px;
}

.bilimolish__2__slayd .swiper-button-next,
.bilimolish__2__slayd .swiper-container-rtl .swiper-button-prev {
  right: -2px;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
}

.bilimolish__3 {
  max-width: 1000px;
  margin: 10px auto;
}

.bilimolish__3__h h2 {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.24em;
  color: #006aff;
}

.bilimolish__3__h h1 {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 58px;
  text-align: center;
  color: #006aff;
  margin-bottom: 36px;
  margin-top: 18px;
}

.bilimolish__3__h h1 span {
  color: #111111;
}
/* 
.bilimolish__3__slayd .swiper-container {
  padding: 0 35px;
} */

.bilimolish__3__slayd .swiper-button-prev:after,
.bilimolish__3__slayd .swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
  background: url(../icons/Left.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  left: 0px;
  z-index: 200;
}

.bilimolish__3__slayd .swiper-button-next:after,
.bilimolish__3__slayd .swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
  background: url(../icons/Right.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  right: 0px;
  z-index: 200;
}

.bilimolish__3__slayd .swiper-button-prev {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.bilimolish__3__slayd .swiper-button-next {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.bilimolish__3__slayd .swiper-button-prev,
.bilimolish__3__slayd .swiper-container-rtl .swiper-button-next {
  left: -2px;
}

.bilimolish__3__slayd .swiper-button-next,
.bilimolish__3__slayd .swiper-container-rtl .swiper-button-prev {
  right: -2px;
}

.bilimolish__4__slayd {
  max-width: 900px;
  margin: 0 auto;
}

.bilimolish__4__slayd .swiper-container {
  padding: 30px 35px;
}

.bilimolish__4__slayd .swiper-button-prev:after,
.bilimolish__4__slayd .swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
  background: url(../icons/Left.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  left: 0px;
  z-index: 200;
}

.bilimolish__4__slayd .swiper-button-next:after,
.bilimolish__4__slayd .swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
  background: url(../icons/Right.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  right: 0px;
  z-index: 200;
}

.bilimolish__4__slayd .swiper-button-prev {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.bilimolish__4__slayd .swiper-button-next {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.bilimolish__4__slayd .swiper-button-prev,
.bilimolish__4__slayd .swiper-container-rtl .swiper-button-next {
  left: -2px;
}

.bilimolish__4__slayd .swiper-button-next,
.bilimolish__4__slayd .swiper-container-rtl .swiper-button-prev {
  right: -2px;
}

.bilimolish__6__slayd .swiper-container {
  padding: 0 35px;
}

.bilimolish__6__slayd .swiper-button-prev:after,
.bilimolish__6__slayd .swiper-container-rtl .swiper-button-next:after {
  content: "" !important;
  background: url(../icons/Left.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  left: 0px;
  z-index: 200;
}

.bilimolish__6__slayd .swiper-button-next:after,
.bilimolish__6__slayd .swiper-container-rtl .swiper-button-prev:after {
  content: "" !important;
  background: url(../icons/Right.svg);
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 35px;
  right: 0px;
  z-index: 200;
}

.bilimolish__6__slayd .swiper-button-prev {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.bilimolish__6__slayd .swiper-button-next {
  background: white !important;
  height: 100%;
  z-index: 100;
  top: 0 !important;
  margin: 0;
}

.bilimolish__6__slayd .swiper-button-prev,
.bilimolish__6__slayd .swiper-container-rtl .swiper-button-next {
  left: -2px;
}

.bilimolish__6__slayd .swiper-button-next,
.bilimolish__6__slayd .swiper-container-rtl .swiper-button-prev {
  right: -2px;
}

.slayder__3 {
  padding: 18px;
  width: 192px;
  height: 269px;
  border: 2px solid rgba(17, 17, 17, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 40px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.slayder__3 .stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 18px;
  margin-bottom: 24px;
}

.slayder__3 .stars p {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #006aff;
  margin-left: 8px;
  margin-right: 20px;
}

.slayder__3 .stars p span {
  color: #006aff50;
}

.slayder__3 h1 {
  font-family: Gilroy-Medium;
  font-size: 20px;
  line-height: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #111111;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.slayder__3 h2 {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(17, 17, 17, 0.54);
  -webkit-margin-before: 2px;
  margin-block-start: 2px;
}

.slayder__3 a {
  width: 156px;
  height: 37px;
  background: rgba(17, 17, 17, 0.06);
  border-radius: 14px;
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: rgba(17, 17, 17, 0.54);
}
.slayder__3 a:hover {
  background: #006aff;
  color: white;
}

.slayd__3__img {
  width: 60px;
  height: 60px;
  margin-bottom: 18px;
  border-radius: 16px;
}

.bilimolish__4 {
  max-width: 1100px;
  margin: 0 auto;
}

.bilimolish__4__h {
  width: 464px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-top: 18px;
}

.bilimolish__4__h h2 {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.24em;
  color: #006aff;
  margin-top: 100px;
}

.bilimolish__4__h h1 {
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: 900;
  font-size: 46px;
  line-height: 58px;
  text-align: center;
  color: #006aff;
}

.bilimolish__4__h h1 span {
  color: #111111;
}

.slayder__4 {
  width: 180px;
  height: 130px;
  background: #ffffff;
  border: 2px solid rgba(17, 17, 17, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0px 20px 30px -2px rgba(17, 17, 17, 0.03);
  box-shadow: 0px 20px 30px -2px rgba(17, 17, 17, 0.03); */
  border-radius: 37px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  /* margin: 0 10px; */
}

.slayder__4 img {
  width: 45px;
  height: 45px;
  border-radius: 14px;
  margin-bottom: 16px;
}

.slayder__4 h1 {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 23px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #111111;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  margin-bottom: 2px;
  font-weight: 400;
}

.slayder__4 h2 {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: rgba(17, 17, 17, 0.54);
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.bilimolish__5__h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 54px;
  margin-top: 18px;
}

.scrollfigure {
  padding: 3px 0;
  box-sizing: border-box;
}

.bilimolish__5__h h2 {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.24em;
  color: #006aff;
  margin-top: 150px;
}

.bilimolish__5__h h1 {
  font-family: "Samsung-Sharp-Sans-Bold";
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 58px;
  text-align: center;
  color: #006aff;
}

.bilimolish__5__h h1 span {
  color: #111111;
}

.bilimolish__6__h {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 36px;
  margin-top: 10px;
}

.bilimolish__6__h h2 {
  font-family: Gilroy-Bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  letter-spacing: 0.24em;
  color: #006aff;
  margin-top: 150px;
}

.bilimolish__6__h h1 {
  width: 520px;
  font-family: "Gilroy-Medium";
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 58px;
  text-align: center;
  color: #006aff;
}

.bilimolish__6__h h1 span {
  color: #111111;
}

.bilimolish__5__feature {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 250px;
  margin-top: 50px;
}

.bilimolish__5__feature .f2 {
  margin-bottom: 126px;
  border-bottom: 2px solid #e2e2e2;
}

.bilimolish__5__feature .f2 p {
  border-left: 2px solid #e2e2e2;
}

.bilimolish__5__feature .f1 {
  margin-top: 125px;
  border-top: 2px solid #e2e2e2;
}

.bilimolish__5__feature .feature1 {
  border-top-left-radius: 18px;
}

.bilimolish__5__feature .feature1 h1 {
  border-top-left-radius: 18px;
}

.bilimolish__5__feature .feature h2 {
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  color: rgba(17, 17, 17, 0.36);
  border-left: 2px solid #e2e2e2;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
  padding: 11px 0 11px 10px;
}

.bilimolish__5__feature .feature p {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(17, 17, 17, 0.3);
  padding: 0px 0 18px 20px;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.f2 .six h1 {
  position: relative;
  display: inline-block;
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  color: rgba(17, 17, 17, 0.36);
  border-left: 2px solid #e2e2e2;
  padding: 11px 0 11px 12px;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.f2 .six h1:before {
  content: "";
  position: absolute;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: white;
  width: 31px;
  height: 5px;
}

.f2 .six h1:before {
  left: 1px;
  top: 0px;
}

.f2 .six h1:after {
  right: 0;
  bottom: 0;
}

.f2 .six h1 span:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border: 3px solid #e2e2e2;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.f2 .six h1 span:before {
  top: 20px;
  left: -7px;
}

.f1 .six h1 {
  position: relative;
  display: inline-block;
  font-family: Gilroy-Medium;
  font-size: 24px;
  line-height: 28px;
  border-left: 2px solid #e2e2e2;
  color: rgba(17, 17, 17, 0.36);
  padding: 11px 0 11px 12px;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.f1 .six h1:before {
  content: "";
  position: absolute;
  -webkit-transform-origin: top left;
  transform-origin: top left;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  background: white;
  width: 44%;
  height: 5px;
}

.f1 .six h1:before {
  left: 1px;
  top: 52px;
}

.f1 .six h1:after {
  right: 0;
  bottom: 0;
}

.f1 .six h1 span:before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border: 3px solid #e2e2e2;
  border-radius: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.f1 .six h1 span:before {
  top: 49px;
  left: -7px;
}

.feature:hover {
  border-color: #006aff;
}

.feature:hover .six h1 span:before {
  border-color: #006aff;
}

.feature:hover .six h1 {
  color: #006aff;
  border-color: #006aff;
}

.feature:hover p {
  color: rgba(17, 17, 17, 0.72);
  border-color: #006aff;
}

.bilimolish__6 {
  max-width: 1000px;
  margin: 0 auto;
}

.bilimolish__6__slayd {
  margin-bottom: 50px;
}

.bilimolish__6__slayd .swiper-slide {
  height: 204px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bilimolish__6__slayd .swiper-slide-next .slayder__6 {
  width: 320px;
  min-height: 182px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.bilimolish__6__slayd .swiper-slide-next .slayder__6__fikr {
  display: flex;
  opacity: 0;
  -webkit-animation-name: text_slider;
  animation-name: text_slider;
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  margin-top: 10px;
}

.slayder__6 {
  width: 239px;
  height: 92px;
  background: #ffffff;
  border: 2px solid rgba(17, 17, 17, 0.04);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 12px 24px 24px 24px;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
}

.slayder__6 .slayder__6__1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
}
.slayder__6 .slayder__6__fikr button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  outline: none;
  border: none;
  width: 50px;
  height: 50px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.slayder__6 .slayder__6__fikr button img {
  width: 50px;
  height: 50px;
}

.f1 .six h1 {
  padding-top: 41px;
  padding-left: 19px;
  box-sizing: border-box;
}
.f2 .six h1 {
  padding-left: 19px;
  box-sizing: border-box;
}

.slayder__6 {
  padding: 10px;
  box-sizing: border-box;
}
.slayder__6 button {
  background: transparent;
  border: none;
  outline: none;
}
.slayder__6 .slayder__6__ism {
  transform: translateY(-4px);
}

.slayder__6 .slayder__6__img {
  width: 70px;
  height: 50px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}
.slayder__6 .slayder__6__1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.slayder__6 .slayder__6__1 img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 15px;
}

.slayder__6 .slayder__6__1 h1 {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 23px;
  color: #111111;
  -webkit-margin-before: 5px;
  margin-block-start: 5px;
  -webkit-margin-after: 5px;
  margin-block-end: 5px;
}

.slayder__6 .slayder__6__1 h2 {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.54);
  -webkit-margin-before: 5px;
  margin-block-start: 5px;
  -webkit-margin-after: 5px;
  margin-block-end: 5px;
}

.slayder__6 .slayder__6__fikr {
  display: none;
  padding: 0 10px;
  box-sizing: border-box;
}

.slayder__6 .slayder__6__fikr p {
  font-family: Gilroy-Medium;
  font-size: 16px;
  line-height: 19px;
  color: rgba(17, 17, 17, 0.72);
}

.maindown__asos {
  margin: 0 auto;
}

.maindown__login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

#root {
  overflow: hidden !important;
}

/* .modal-alert {
  display: none;
} */

.sec__1_video__2:hover {
  cursor: pointer;
}

.wrapper-video {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 50px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.425);
  z-index: 99;
}

.videoPromo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.videoPromo video {
  width: 70%;
  height: auto;
}

.closeVideo {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10%;
  right: 10%;
}

.closeVideo:hover {
  cursor: pointer;
}

.maindown__login a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: #006aff;
  margin-top: 26px;
  margin-bottom: 10px;
}

.maindown__login a img {
  width: 17px;
  height: 20px;
}

.maindown__asos {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  max-width: 1132px;
  height: 365px;
  background: #006aff;
  border-radius: 72px;
  overflow: hidden;
}

@media only screen and (max-width: 1150px) {
  .maindown__asos {
    max-width: 100%;
    margin: 0 0;
    border-radius: 0;
  }
}

.maindown__asos .maindown__1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin-top: -70px;
}

.maindown__asos .maindown__1__h h1 {
  font-family: Gilroy-Bold;
  font-size: 72px;
  line-height: 84px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fafafa;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.maindown__asos .maindown__1__h p {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  min-width: 394px;
  padding-left: 10px;
  color: rgba(250, 250, 250, 0.72);
  -webkit-margin-before: 0;
  margin-block-start: 0;
  -webkit-margin-after: 0;
  margin-block-end: 0;
}

.maindown__asos .maindown__1__img img {
  position: relative;
  -webkit-filter: opacity(15%);
  filter: opacity(15%);
}

.maindown__asos .maindown__2 {
  margin-top: -40px;
  margin-left: 40px;
  display: flex !important;
}

.error-text-main {
  color: rgba(250, 250, 250, 0.842);
  background: rgba(250, 250, 250, 0.24);
  max-width: 700px;
  margin-left: 100px;
  margin-top: 10px;
  border-radius: 10px;
  text-align: center;
}

.maindown__asos .maindown__2 input {
  width: 221px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(250, 250, 250, 0.24);
  border-radius: 24px;
  border: none;
  outline: none;
  margin-left: 15px;
  margin-right: 15px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.72);
  padding-left: 15px;
}

.maindown__asos .maindown__2 .input {
  width: 250px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: rgba(250, 250, 250, 0.24);
  border-radius: 24px;
  border: none;
  outline: none;
  margin-left: 15px;
  margin-right: 15px;
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.72);
  padding-left: 15px;
}

.maindown__asos .maindown__2 input::-webkit-input-placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.maindown__asos .maindown__2 input:-ms-input-placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.maindown__asos .maindown__2 input::-ms-input-placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.maindown__asos .maindown__2 input::placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.maindown__asos .maindown__2 .input::-webkit-input-placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.maindown__asos .maindown__2 .input:-ms-input-placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.maindown__asos .maindown__2 .input::-ms-input-placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.maindown__asos .maindown__2 .input::placeholder {
  font-family: Gilroy-Medium;
  font-size: 18px;
  line-height: 21px;
  color: rgba(250, 250, 250, 0.842);
}

.mobile-button-all {
  display: none;
  font-size: 18px;
  font-weight: 400;
  color: #006aff;
}

.login_btn {
  position: relative;
}

.login_btn:hover .login_popup {
  opacity: 1;
  visibility: visible;
  transform: translateX(-50%) translateY(0);
  pointer-events: all;
}

.login_popup {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateY(20px);
  padding-top: 20px;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  pointer-events: none;
}

.login_popu__body {
  background-color: #e7f0ff;
  border-radius: 8px;
  min-width: 160px;
}

.login_popup__item {
  padding: 10px 20px;
  text-align: center;
  position: relative;
  color: #006aff;
  cursor: pointer;
}

.login_popup__item:not(:last-child):before {
  content: "";
  width: 70%;
  height: 1px;
  background-color: #bdc4d0;
  left: 50%;
  transform: translateX(-50%);
  top: 100%;
  position: absolute;
}

.maindown__asos .maindown__2 button {
  width: 255px;
  height: 82px;
  background: #fafafa;
  border-radius: 24px;
  font-family: Gilroy-Bold;
  font-size: 18px;
  line-height: 21px;
  color: #006aff;
  border: none;
  outline: none;
  margin-left: 15px;
  margin-right: 15px;
}

.course-loader-item {
  margin-bottom: 9px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: black;
  background: linear-gradient(to right, #b3d7ff 8%, black 18%, blue 33%);
  background-size: 1000px 104px;
}

.white.course-loader .course-loader-item {
  background-image: linear-gradient(
    to right,
    red 8%,
    red 18%,
    red 33%
  ) !important;
  animation-name: placeHolderShimmer;
}

.course-loader-item:last-child {
  margin-bottom: 0;
}

.course-loader-item.circled {
  margin-bottom: 20px;
  border-radius: 5px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.loaderc {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}

.loaderc div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 8px;
  border: 8px solid #ffd634;
  border-radius: 50%;
  animation: loaderc 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #006aff #b3d7ff;
}

.loaderc div:nth-child(1) {
  animation-delay: -0.45s;
}

.loaderc div:nth-child(2) {
  animation-delay: -0.3s;
}

.loaderc div:nth-child(3) {
  animation-delay: -0.15s;
}

.discountboxCourse {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
}

.discount-price-top {
  color: #006aff;
  text-decoration: line-through;
  font-size: 17px;
  font-family: Gilroy;
}

.discount-price-bottom {
  font-size: 15px;
  color: #000;
  font-family: Gilroy-Medium;
}

@keyframes loaderc {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes text_slider {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes text_slider {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media (max-width: 860px) {
  .bilimolish__6__h h2 {
    margin-top: 30px;
  }

  .maindown__asos .maindown__1__h p {
    min-width: unset;
    font-size: 13px;
  }
}

@media (max-width: 425px) {
  .section__1 .swiper-container,
  .section__1 .swiper-container .swiper-wrapper .swiper-slide {
    padding: 0 !important;
  }
  .main_page-wrapper h1 {
    font-size: 25px !important;
  }
  .main_page-wrapper h4 {
    font-size: 16px !important;
  }
  .main_page-wrapper_in {
    width: 100%;
  }
  .section__1 .swiper-container .swiper-button-prev,
  .section__1 .swiper-container .swiper-button-next {
    display: none;
  }
  .sec__1_btn button {
    width: 54px;
    height: 24px;
    background: #006aff;
    border-radius: 24px;
    outline: none;
    border: none;
    margin: 0 20px;
  }
  .plyr__controls .plyr__controls__item .plyr__volume {
    display: none !important;
  }

  .maindown__asos .maindown__1__h p {
    min-width: unset;
    font-size: 13px;
  }

  .sec__1_btn h4,
  .sec__1_btn h5 {
    font-size: 24px;
    font-weight: 400;
  }

  .d-none {
    display: none !important;
  }

  .sec__1_h1 h1 {
    font-size: 35px;
    font-weight: 700;
    margin-right: 20px;
  }

  .sec__1_btn {
    margin-left: 0;
  }

  .bilimolish__2__h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
  }

  .bilimolish__2__h1 a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 15px !important;
  }

  .bilimolish__2__h1 span {
    margin-left: 10px;
  }

  .bilimolish__2__h1 h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-size: 20px !important;
    font-weight: 700;
    line-height: 24px !important;
    text-align: start;
  }

  .mobile-button-all {
    display: block;
    font-family: "Samsung-Sharp-Sans-Bold";
  }

  .bilimolish__2__a {
    display: none;
  }

  .bilimolish__3__h h1 {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 10px;
  }

  .bilimolish__4__h h1 {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 10px;
    margin: 0 50px;
  }

  .bilimolish__4__h {
    margin-top: 0;
  }

  .bilimolish__6__h {
    margin-bottom: 0 !important;
  }

  .bilimolish__2__h1 {
    margin-bottom: 10px !important;
  }

  .maindown__1__h h1 {
    font-size: 40px !important;
  }
}

.ramadan-box {
  padding: 25px 20px;
  background-color: #e7f0ff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  top: 20%;
  right: 0;
}
.ramadan-img img {
  max-width: 50px;
  height: auto;
  animation-name: ramadanImg;
  animation-duration: 12s;
  animation-iteration-count: infinite;
  animation-timing-function: all linear;
  animation-delay: 3s;
}

@keyframes ramadanImg {
  0% {
    transform: rotateY(0);
  }
  25% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(360deg);
  }
}

.suxur-box,
.iftar-box {
  margin-top: 20px;
  text-align: center;
}

.suxur-box .suxur-text,
.iftar-box .iftar-text {
  font-size: 17px;
  color: #303030;
  font-family: "Gilroy-Medium";
}
.suxur-box .suxur-time,
.iftar-box .iftar-time {
  font-size: 20px;
  font-weight: 600;
  color: #006aff;
}

@media (max-width: 514px) {
  .ramadan-box {
    top: 20%;
  }
  .ramadan-box {
    padding: 20px 10px;
  }

  .ramadan-img img {
    max-width: 35px;
  }

  .suxur-box,
  .iftar-box {
    margin-top: 13px;
  }

  .suxur-box .suxur-text,
  .iftar-box .iftar-text {
    font-size: 15px;
  }
  .suxur-box .suxur-time,
  .iftar-box .iftar-time {
    font-size: 17px;
  }
}

@media (max-width: 400px) {
  .maindown__asos .maindown__2 {
    width: 150px !important;
  }

  .slayder__4 {
    margin: 0 auto;
  }
}
@media (max-width: 1080px) {
  .bilimolish__5__feature {
    padding: 0 10px;
  }
}
@media (max-width: 600px) {
  .maindown__asos .maindown__2 {
    width: 200px !important;
  }
  .slayder__4 {
    margin: 0 auto;
  }

  .ramadan-box {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 10px;
    padding: 20px 15px;
  }

  .ramadan-img img {
    max-width: 40px;
  }
}

@media (max-width: 365px) {
  .swipper__1 {
    width: 100%;
  }

  .swipper__1 .bilimolish__2__slayd__img {
    position: relative;
    width: 100%;
  }
  .swipper__1 .imgbox {
    width: 55px;
    height: 55px;
    position: absolute;
    right: 5px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .swipper__1 .imgbox img {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px;
    margin: 0;
    box-sizing: border-box;
  }

  .swipper__1 .imgbig__div {
    width: 100%;
  }
  .swipper__1 .imgbig__div img {
    width: 100%;
  }
}

.swipper__1 .bilimolish__2__slayd__img {
  position: relative;
  width: 100%;
}

.bilimolish__2__slayd__img .imgbox {
  width: 55px;
  height: 55px;
  position: absolute;
  right: 5px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bilimolish__2__slayd__img .imgbox img {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  margin: 0;
  box-sizing: border-box;
}

.bilimolish__2__slayd__img .imgbig__div {
  width: 100%;
  height: 100%;
}

.bilimolish__2__slayd__img .imgbig__div img {
  width: 100%;
  height: 100%;
}

#avtor {
  font-family: Gilroy-Medium;
  font-size: 14px;
  line-height: 19px;
  color: #006aff;
}

@media only screen and (max-width: 489px) {
  .sec__1_h1 h1 {
    line-height: 50px;
    margin-bottom: 10px;
  }
  .sec__1_group {
    flex-direction: row;
  }
  .slayder__3 {
    width: 135px;
    border-radius: 20px;
    margin: 0 5px;
    padding: 10px;
    height: unset;
  }
  .slayder__3 h2 {
    font-size: 10px;
    font-weight: 300;
  }
  .slayder__3 h1 {
    font-size: 15px;
  }
  .slayder__3 a {
    width: 110px;
    border-radius: 10px;
  }
  .slayder__3 .stars {
    margin: 0 10px 0 15px 0;
  }
  .slayder__3 .swiper-slide.swiper-slide-duplicate {
    width: 150px !important;
  }
  .bilimolish__3__slayd {
    padding: unset !important;
  }

  .slayder__4 {
    margin: 0;
    width: 100%;
    height: unset;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }
  .slayder__4 img {
    margin-bottom: 0;
  }
  .bilimolish__4__h h1 {
    font-size: 25px;
  }
  .maindown__1__h {
    padding-left: 0 !important;
  }
}

@media only screen and (max-width: 1100px) {
  .maindown__asos .maindown__1__h p {
    padding-left: 0;
  }
}

@media only screen and (max-width: 980px) {
  .maindown__1__h {
    padding-left: 20px;
  }

  .maindown__1__h h1 {
    font-size: 50px !important;
  }
}

@media only screen and (max-width: 768px) {
  .main_page-wrapper h1 {
    font-size: 2.5rem;
  }
  .main_page-img_box {
    margin: 0 !important;
  }
  .main_page-wrapper h4 {
    font-size: 1rem;
  }
  .main_page-img_box img {
    width: 100%;
  }
  .maindown__asos .maindown__2 button {
    margin-left: 5px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 690px) {
  .maindown__asos .maindown__2 button {
    margin-top: 10px;
    margin-left: 15px;
  }
}

@media only screen and (max-width: 545px) {
  .maindown__1__img {
    margin-right: -80px;
  }
}

@media only screen and (max-width: 532px) {
  .maindown__1__h {
    margin: 0 auto;
    padding-top: 18px;
  }
}

@media only screen and (max-width: 860px) {
  .maindown__asos .maindown__1 {
    margin-top: -40px;
  }
}

@media only screen and (max-width: 800px) {
  .maindown__asos .maindown__2 .input {
    margin-right: 5px;
    margin-left: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
  }

  .maindown__asos .maindown__2 button {
    height: 50px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .maindown__asos .maindown__2 .input {
    margin: 10px auto;
  }
}

/* @media only screen and (max-width: 767px) {
  .slayder__4 {
    margin: 0 0;
  }
} */

.intro__video-wrapper {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: rgba(68, 68, 68, 0.425);
}

.intro__video {
  width: 100%;
  max-width: 70%;
  height: 100%;
  max-height: 80%;
  border: none;
}

@media only screen and (max-width: 1200px) {
  .intro__video {
    max-height: 73%;
  }
}

@media only screen and (max-width: 1080px) {
  .intro__video {
    max-height: 66%;
  }
}

@media only screen and (max-width: 900px) {
  .intro__video {
    max-width: 90%;
    max-height: 70%;
  }
}

@media only screen and (max-width: 700px) {
  .intro__video {
    max-width: 90%;
    max-height: 55%;
  }
}

@media only screen and (max-width: 550px) {
  .intro__video {
    max-width: 90%;
    max-height: 43%;
  }
}

@media only screen and (max-width: 430px) {
  .intro__video {
    max-width: 95%;
    max-height: 35%;
  }
  .discountboxCourse {
    flex-direction: row;
    align-items: flex-end;
    margin-left: 0;
  }
  .discountboxCourse .discount-price-bottom {
    margin-left: 7px;
    font-size: 17px;
  }
  .discountboxCourse .discount-price-top {
    font-size: 15px;
  }
}

@font-face {
  font-family: "Gilroy-Medium";
  src: url(../../components/fonts/Gilroy-Bold.ttf);
}

.footer-part {
  width: 100%;
  background: blue;
  color: #fff;
  padding: 15px 0;
  font-family: "Gilroy-Medium";
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 9;

  .footer-part__list {
    display: flex;
    padding: 0 10px;
    justify-content: space-around;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;

    .footer-part-list {
      width: 400px;
      justify-content: space-between;
      align-items: center;
      display: none;
      flex-wrap: wrap;
    }

    .footer-part__item {
      display: flex;
      align-items: center;
      column-gap: 10px;
      row-gap: 10px;
      cursor: pointer;

      span {
        font-size: 18px;

        span {
          width: 200px;
        }

        @media (max-width: 893px) {
          font-size: 14px;
        }
      }
    }

    .footer-part-list__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;

      span {
        margin-top: 5px;
        font-size: 12px;

        @media (max-width: 893px) {
          font-size: 10px;
        }
      }
    }
  }
}

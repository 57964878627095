
/* section - 6 */
@font-face{
    font-family: 'Gilroy-Medium';
    src: url('../fonts/Gilroy-Medium.ttf');
}
.footLogo{
    max-width: 100px;
}
.footer{
    display: grid;
    grid-template-columns: 4fr 2fr 2fr 2fr;
    padding: 0 25px;
    padding-top: 45px;
    border-top: 2px solid rgba(17, 17, 17, 0.06);
    margin-top: 50px !important;
}

.spiker{
    border: 2px solid rgba(17, 17, 17, 0.06);
    box-sizing: border-box;
    border-radius: 18px;
    margin-top: 12px;
    padding: 3px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
}

.spiker p{
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    padding: 16px 30px;
    cursor: pointer;
    margin: 0 5px;
    border-radius: 12px;
    transition: 0.3s linear;
}

.active{
    color: #fff !important;;
    background: #006AFF!important;

}
.pass{
    color: #111;
}

.social{
    display: flex;
    align-items: center;
}
.social .insta{
    padding-left: 0;
    margin-left: 0;
}
.social img{
    padding: 7px;
    margin: 0 6px;
}

.footer_right{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 70%;
}

.footer_block{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

.footer_block p{
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    color: rgba(17, 17, 17, 0.54);
    margin-bottom: 55px;
}

.footer_block a{
    text-decoration: none;
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    color: #111111;
    margin-bottom: 25px;
}
.footer_block a:hover{
color: #006AFF;
}
.footer_vector{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;
    padding: 30px 0 50px 0;
    border-top: 2px solid rgba(17, 17, 17, 0.06);
}

.footer_vector p{
    font-family: 'Gilroy-Medium';
    font-size: 16px;
    color: rgba(17, 17, 17, 0.36);
}

.footer_vector span{
    color:#006AFF;
}

.footer_left{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}
.footer_left > img{
    transform: translateY(-15px);
}

.footer_left_mobil{
    display: none;
}


@media(max-width:1300px){
    .footer_vector{
        width: 80%;
        margin-top: 50px !important;
        margin: auto;
    } 
}

@media(max-width:1024px){
    .footer_left p, .footer_block p, .footer_block a{
        font-size: 12px;
    }
    .spiker p{
        font-size: 16px;
        padding: 10px 15px;
        margin: 0 2px;
    }
}


@media(max-width:768px){
    .footer{
        grid-template-columns: 1fr 1fr;
        grid-gap: 25px;
        width: 84%;
        margin: auto;
    }
    .footer_left, .footer_block{
        padding-left: 30px;
    }
    .footer_block_bottom{
        margin-top: 20px;
    }
    .footer_vector{
        margin-top: 30px !important;
    } 
    .footer_vector p{
        font-size: 14px;
    }
    .spiker p{
        font-size: 12px;
    }
}

@media(max-width:425px){
    .footer{
        grid-template-columns: 1fr;
    }
    .footer_left, .footer_block{
        align-items: center;
    }
    .footer_block a{
        display: block;
        text-align: center;
    }
    .footer_left > img{
        transform: translateY(0);
    }
    .footer {
        grid-gap: 54px;
    }
    .footer_block p {
        margin-bottom: 30px;
        margin-top: 0 !important;
    }
    .footer_left, .footer_block{
        padding-left: 0;
        margin-top: 0;
    }
    .footer_left_mobil{
        display: flex;
    }
    .spiker_pc{
        display: none;
    }
    .spiker{
        margin-bottom: 38px;
        margin-top: 0;
    }
    .footer_block_bottom{
        margin-top: 0;
    }
}

@media(max-width:350px){
    .footer_vector p{
        font-size: 12px;
    }
}